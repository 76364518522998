import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Clients from "./Clients.js";
import Products from "./Products.js";
import Reports from "./Reports.js";
import Services from "./Services.js";
import ServocesDates from "./ServocesDates.js";
import Units from "./Units.js";
import Vehicles from "./Vehicles.js";
import Washers from "./Washers.js";
import Schedule from "./Schedule.js";

Vue.use(VueRouter);

const routes = [
  ...Clients,
  ...Products,
  ...Reports,
  ...Services,
  ...ServocesDates,
  ...Units,
  ...Vehicles,
  ...Washers,
  ...Schedule,
  {
    path: "/menu",
    name: "menu",
    component: () =>
      import(/* webpackChunkName: "menu" */ "../views/menuView.vue"),
  },
  {
    path: "/forbiden",
    name: "forbiden",
    meta: { isPublic: true },
    component: () =>
      import(/* webpackChunkName: "clients" */ "../views/Errors/403.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  const storedToken = store.getters.token;
  const token = to?.query?.token || storedToken;
  if (to.meta.isPublic) return next();
  if (token) store.commit("token", token);

  if (store.getters.token) next();
  else next("/forbiden");
});

export default router;
