<template>
  <div class="money">
    <p class="i-text" v-if="amount">{{ formatAmount }}</p>
    <p v-else>{{ `${prefix} 00.00` }}</p>
  </div>
</template>

<script>
import { toMoney } from "@/utils/Numeric";

export default {
  computed: {
    formatAmount() {
      return toMoney(this.amount, { decimal: this.decimal, sign: this.prefix });
    },
  },
  props: {
    prefix: { type: String, default: null },
    decimal: { type: Number, default: 2 },
    amount: { type: Number, default: 0 },
  },
};
</script>

<style lang="sass" scoped>
.money
  .i-text
    margin: 0
    font-size: 14px
</style>
