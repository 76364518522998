<template>
  <div class="field">
    <h5 class="mb-2" v-if="title">{{ title }}</h5>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
  },
};
</script>

<style lang="sass" scoped>
.field
  h5
    opacity: .8
    font-size: 16px
    text-transform: lowercase
    &::first-letter
          text-transform: uppercase
</style>
