<template>
  <FormItemContainer :title="title">
    <v-select
      :item-text="text"
      :item-value="model"
      :items="items"
      :rules="myRules"
      :label="label"
      @input="onInput"
      solo
      v-bind="{ ...$attrs, ...$props }"
    />
  </FormItemContainer>
</template>

<script>
import FormItemContainer from "./FormItemContainer";
import { validateRequired } from "@/utils/Validations";

export default {
  components: {
    FormItemContainer,
  },
  mounted() {
    if (this.apiUrl) this.getItems();
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    myRules() {
      const rules = [];
      if (this.required) rules.push((v) => validateRequired(v));
      return [...rules, ...this.rules];
    },
  },
  methods: {
    onInput(value) {
      this.$emit("select", value);
    },
    async getItems() {
      try {
        const { data } = await this.Api.get(this.apiUrl);
        this.items = data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    data(value) {
      this.items = value;
    },
  },
  props: {
    apiUrl: { type: String, default: null },
    model: { type: String },
    outlined: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    text: { type: String },
    title: { type: String, default: null },
    value: { default: null },
    label: { type: String, default: null },
    data: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped></style>
