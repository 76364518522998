export default [
  {
    path: "/washers",
    name: "washers",
    component: () =>
      import(
        /* webpackChunkName: "washer" */ "../views/Washers/WashersList.vue"
      ),
  },
  {
    path: "/washers/add",
    name: "Add washers",
    component: () =>
      import(
        /* webpackChunkName: "Add washer" */ "../views/Washers/WashersForm.vue"
      ),
  },
  {
    path: "/washers/:washerId(\\d+)",
    name: "Edit washers",
    component: () =>
      import(
        /* webpackChunkName: "Edit washer" */ "../views/Washers/WashersForm.vue"
      ),
  },
  {
    path: "/washers/:washerId(\\d+)/services",
    name: "Washers services",
    component: () =>
      import(
        /* webpackChunkName: "Edit washer" */ "../views/Washers/WashersServices.vue"
      ),
  },
  {
    path: "/washers/:washerId(\\d+)/services/:serviceId(\\d+)",
    name: "Washers services details",
    component: () =>
      import(
        /* webpackChunkName: "Washer services details" */ "../views/Washers/WashersServiceDetails.vue"
      ),
  },
];
