<template>
  <FormItemContainer :title="title">
    <Field
      v-model="dateFormatted"
      @click="() => (modal = true)"
      :label="labelFormat"
      prepend-inner-icon="mdi-calendar-clock"
      readonly
      required
    />
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent>
      <v-date-picker
        v-model="date"
        :min="min"
        :max="max"
        no-title
        scrollable
        :range="isRange"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="() => (modal = false)">
          Cancelar
        </v-btn>
        <v-btn text color="primary" @click="saveDates"> Guardar </v-btn>
      </v-date-picker>
    </v-dialog>
  </FormItemContainer>
</template>

<script>
import Field from "./Field.vue";
import FormItemContainer from "./FormItemContainer";
export default {
  components: {
    FormItemContainer,
    Field,
  },
  beforeMount() {
    if (!this.value) {
      this.dates = { startDate: false };
    } else {
      this.dates = this.value;
      this.date = this.isRange
        ? [this.value.startDate, this.value.endDate]
        : this.value.startDate;
    }
  },
  data() {
    return {
      modal: false,
      date: this.currentDate(),
      dates: {
        startDate: this.currentDate(),
        endDate: this.currentDate(),
      },
    };
  },
  computed: {
    dateFormatted() {
      let dateValue;
      if (!this.dates.startDate) return;

      if (this.isRange) {
        dateValue = `${this.$moment(this.dates.startDate).format(
          this.format
        )} - ${this.$moment(this.dates.endDate).format(this.format)}`;
      } else {
        dateValue = this.$moment(this.dates.startDate).format(this.format);
      }
      return dateValue;
    },
    labelFormat() {
      return this.isRange
        ? `${this.format} - ${this.format}`
        : `${this.format}`;
    },
  },
  methods: {
    currentDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    saveDates() {
      this.$refs.dialog.save(this.date);
      this.$emit("input", this.dates);
    },
    createObject(firstDate, secondDate) {
      return {
        startDate: firstDate,
        endDate: secondDate || firstDate,
      };
    },
  },
  watch: {
    date(currentDates) {
      if (!this.isRange) {
        console.log(currentDates);
        this.dates = {
          startDate: currentDates,
          endDate: null,
        };
        return;
      }

      let currentObjDates = {};
      const valueOfStart = this.$moment(currentDates[0]).valueOf();
      const valueOfEnd = this.$moment(currentDates[1]).valueOf();

      if (currentDates.length >= 2) {
        if (valueOfStart > valueOfEnd) {
          if (currentDates[1].length > 1) {
            currentObjDates = this.createObject(
              currentDates[1],
              currentDates[0]
            );
          } else currentObjDates = this.createObject(this.currentDate());
        } else {
          currentObjDates = this.createObject(currentDates[0], currentDates[1]);
        }
      } else {
        currentObjDates = this.createObject(currentDates[0]);
      }

      this.dates = currentObjDates;
    },
  },
  props: {
    format: { type: String, default: "DD/MM/YYYY" },
    isRange: { type: Boolean, default: false },
    max: { type: String, default: null },
    min: { type: String, default: null },
    title: { type: String, default: null },
    value: { type: Object, default: null },
  },
};
</script>

<style lang="sass" scoped></style>
