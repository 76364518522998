<template>
  <div class="scale-input">
    <Button
      color="primary"
      iconCenter="mdi-minus"
      @click="decrease"
      :disabled="disableDecrease"
    />
    <input
      type="number"
      max="100"
      :min="this.min"
      v-model="iValue"
      class="input"
    />
    <Button color="primary" iconCenter="mdi-plus" @click="increase" />
  </div>
</template>

<script>
import { validateRequired } from "@/utils/Validations";
import Button from "../Button.vue";

export default {
  components: {
    Button,
  },
  mounted() {
    if (this.apiUrl) this.getItems();
  },
  data() {
    return {
      items: [],
      iValue: this.value,
    };
  },
  computed: {
    myRules() {
      const rules = [];
      if (this.required) rules.push((v) => validateRequired(v));
      return [...rules, ...this.rules];
    },
    disableDecrease() {
      return this.iValue === this.min;
    },
  },
  methods: {
    increase() {
      const value = this.iValue + this.step;

      if (value > 100) return;
      this.iValue = value;
    },
    decrease() {
      const value = this.iValue - this.step;

      if (value < this.min) return;
      this.iValue = value;
    },
    async getItems() {
      try {
        const { data } = await this.Api.get(this.apiUrl);
        this.items = data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    iValue(e) {
      this.iValue = parseInt(e, 10);
      this.$emit("input", this.iValue);
      this.$emit("select", this.iValue);
    },
    value(e) {
      this.iValue = e;
    },
  },
  props: {
    apiUrl: { type: String, default: null },
    label: { type: String, default: "Seleccionar" },
    model: { type: String },
    outlined: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    text: { type: String },
    title: { type: String, default: null },
    value: { default: 0 },
    step: { type: Number, default: 1 },
    min: { type: Number, default: 0 },
  },
};
</script>

<style lang="sass" scoped>
.scale-input
  .Button
    margin-bottom: 0
    border-radius: 50%
    min-width: 0 !important
    width: 20px !important
    height: 20px !important
    padding: 0 !important
    ::v-deep
      .v-btn__content
        font-size: 16px
  .v-btn--disabled
    border: 1px solid rgba(0, 0, 0, 0.26) !important
  .input
    background-color: white
    width: 80px
    border: 1px solid rgba(0, 0, 0, 0.26)
    border-radius: 4px
    margin: 0 12px
    padding: 8.5px
    height: 30px
    text-align: center
</style>
