<template>
  <div
    ref="iScroll"
    class="infinite-scroll"
    :class="data.length ? '' : 'centered'"
  >
    <slot v-if="data.length" :data="data" />
    <div
      class="loading"
      :class="oLoading ? 'full-height' : ''"
      v-if="
        !paused &&
        ((totalLoaded < total_items && totalLoaded >= perPage) || oLoading)
      "
    >
      <div class="loading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        />
      </div>
    </div>
    <Empty v-else-if="!oLoading && !data.length" />
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
export default {
  components: {
    Empty,
  },
  mounted() {
    this.prepareParams();
    if (this.autoInit) this.getData();
    this.$refs.iScroll.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    if (this.$refs.iScroll)
      this.$refs.iScroll.removeEventListener("scroll", this.handleScroll);
    this.cancelRequest("Component destroyed");
  },
  data() {
    return {
      cancelToken: null,
      data: this.value || [],
      iCurrentPage: this.currentPage,
      oLoading: this.loading,
      scrollTimeout: 0,
      totalLoaded: 0,
      total_items: 0,
      myParams: "",
    };
  },
  methods: {
    cancelRequest(message = "Avoid multiple") {
      if (this.cancelToken) this.cancelToken.cancel(message);
      this.oLoading = false;
    },
    handleScroll(e) {
      if (this.paused) return;
      clearTimeout(this.scrollTimeout);
      const { scrollHeight, offsetHeight, scrollTop } = e.target;
      const scroll = scrollTop + offsetHeight;
      this.scrollTimeout = setTimeout(() => {
        if (scroll > scrollHeight - 200) this.nextPage();
      }, 300);
    },
    async getTotalItems() {
      const { myParams, api } = this;
      const { data } = await this.Api.get(`${api}/total_items${myParams}`, {
        cancelToken: this.cancelToken.token,
      });
      this.total_items = data.total_items;
    },
    async getData() {
      const { myParams, api } = this;
      this.cancelRequest();
      this.oLoading = true;
      try {
        this.cancelToken = this.Api.cancelToken;
        if (!this.total_items) await this.getTotalItems();
        const { data } = await this.Api.get(`${api}${myParams}`, {
          cancelToken: this.cancelToken.token,
        });
        this.totalLoaded = this.totalLoaded + data.length;
        this.data = [...this.data, ...data];
        this.$emit("update", { page: this.iCurrentPage, items: this.data });
      } catch (error) {
        console.error(error);
      }
      this.oLoading = false;
    },
    nextPage() {
      if (!this.oLoading && this.totalLoaded < this.total_items) {
        this.iCurrentPage += 1;
        this.prepareParams();
        this.getData();
      }
    },
    prepareParams() {
      const myParams = this.params.concat([
        `page=${this.iCurrentPage}`,
        `per_page=${this.perPage}`,
        ...this.internalParams,
      ]);
      this.myParams = `?${myParams.join("&")}`;
    },
    reset() {
      this.iCurrentPage = 1;
      this.totalLoaded = 0;
      this.total_items = 0;
      this.data = [];
      this.prepareParams();
      this.$emit("update", { page: 1, items: [] });
      this.$emit("input", []);
    },
  },
  watch: {
    oLoading(value) {
      this.$emit("update:loading", value);
    },
    data(value) {
      this.$emit("input", value);
    },
    value(value) {
      this.data = value;
    },
    params(value) {
      this.myParams = value;
      this.reset();
      this.getData();
    },
    total_items(value) {
      this.$emit("totalItems", value);
    },
  },
  props: {
    api: { type: String, required: true },
    autoInit: { type: Boolean, default: () => true },
    currentPage: { type: Number, default: 1 },
    injectThisProps: { type: Object, default: () => {} },
    loading: { type: Boolean, default: () => true },
    params: { type: Array, default: () => [] },
    internalParams: { type: Array, default: () => [] },
    paused: { type: Boolean, default: false },
    perPage: { type: Number, default: 10 },
    value: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped>
.infinite-scroll
  display: flex
  flex-flow: column
  min-height: 400px
  &.centered
    align-items: center
    justify-content: center
  ::v-deep
    .list-item
      padding: 0 5px
.loading
  display: flex
  justify-content: center
  min-height: 150px
.full-height
  height: 100%
.loading-sm
  .loading
    height: fit-content
.infinite-scroll
  height: 100%
  overflow: auto
.results
  color: $gray-500
  margin-bottom: 10px
</style>
