import Vue from "vue";
import Vuex from "vuex";
import Api from "@/utils/Api.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || null,
    layoutLoading: false,
  },
  getters: {
    token: ({ token }) => token,
    layoutLoading: ({ layoutLoading }) => layoutLoading,
  },
  mutations: {
    layoutLoading(state, value) {
      state.layoutLoading = value;
    },
    token(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
      Api.Authorization = token;
      // axios.defaults.headers = { Authorization: data.Authorization };
    },
  },
  actions: {},
  modules: {},
});
