<template>
  <FormItemContainer class="textarea" :title="title">
    <v-textarea
      v-bind="{ ...$attrs, ...$props, label: '' }"
      @input="onInput"
      :rules="myRules"
      :label="label"
    />
  </FormItemContainer>
</template>

<script>
import FormItemContainer from "./FormItemContainer";

export default {
  components: {
    FormItemContainer,
  },
  computed: {
    myRules() {
      const rules = [];
      if (this.required) rules.push((v) => !!v || "El campo es requerido");

      return [...rules];
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", e);
    },
  },
  props: {
    title: { type: String, default: null },
    label: { type: String, default: null },
    outlined: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
  },
};
</script>

<style lang="sass" scoped></style>
