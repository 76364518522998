<template>
  <FormItemContainer :title="title">
    <v-switch
      v-bind="{ ...$attrs, ...$props }"
      v-model="myModel"
      class="iSwitch"
      :class="reversed ? 'reversed' : ''"
    />
  </FormItemContainer>
</template>

<script>
import FormItemContainer from "./FormItemContainer";

export default {
  components: {
    FormItemContainer,
  },
  data() {
    return {
      myModel: this.value,
    };
  },
  watch: {
    value(val) {
      this.myModel = val;
    },
    myModel(val) {
      const boolean = !!val;
      const numeric = val ? 1 : 0;
      this.$emit("input", this.useNumeric ? numeric : boolean);
    },
  },
  props: {
    title: { type: String, default: null },
    label: { type: String, required: true },
    reversed: { type: Boolean, default: true },
    useNumeric: { type: Boolean, default: true },
    value: { type: [Boolean, Number], default: false },
  },
};
</script>

<style lang="sass" scoped>
.iSwitch
  margin: 0
  padding: 0
  &.reversed
    ::v-deep
      .v-input__slot
        flex-flow: row-reverse
</style>
