<template>
  <FormItemContainer :title="title">
    <v-checkbox
      v-bind="{ ...$attrs, ...$props }"
      :rules="myRules"
      v-model="ivalue"
    />
  </FormItemContainer>
</template>

<script>
import FormItemContainer from "./FormItemContainer";

export default {
  components: {
    FormItemContainer,
  },
  data() {
    return {
      ivalue: this.value,
    };
  },
  computed: {
    myRules() {
      const rules = [];
      if (this.required) rules.push((v) => !!v || "El campo es requerido");
      return [...rules, ...this.rules];
    },
  },
  watch: {
    ivalue(value) {
      this.$emit("select", value);
    },
  },
  props: {
    outlined: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    title: { type: String, default: null },
    value: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped></style>
