export default [
  {
    path: "/services",
    name: "services",
    component: () =>
      import(
        /* webpackChunkName: "services" */ "../views/Services/ServicesList.vue"
      ),
  },
  {
    path: "/services/add",
    name: "Add service",
    component: () =>
      import(
        /* webpackChunkName: "Add service" */ "../views/Services/ServicesForm.vue"
      ),
  },
  {
    path: "/services/:id(\\d+)",
    name: "Edit service",
    component: () =>
      import(
        /* webpackChunkName: "Edit service" */ "../views/Services/ServicesForm.vue"
      ),
  },
];
