export default [
  {
    path: "/units",
    name: "Units",
    component: () =>
      import(/* webpackChunkName: "units" */ "../views/Units/UnitsList.vue"),
  },
  {
    path: "/units/add",
    name: "Add units",
    component: () =>
      import(
        /* webpackChunkName: "Add units" */ "../views/Units/UnitsForm.vue"
      ),
  },
  {
    path: "/units/:unitId(\\d+)",
    name: "Edit units",
    component: () =>
      import(
        /* webpackChunkName: "Edit units" */ "../views/Units/UnitsForm.vue"
      ),
  },
];
