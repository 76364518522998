<template>
  <v-card outlined class="container p-2">
    <slot />
  </v-card>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.container
  height: calc(100vh - 140px)
  margin: .75rem
  padding: .75rem
  overflow: auto
</style>
