<template>
  <v-btn
    tile
    v-bind="{ ...$props, ...$attrs }"
    :class="classes"
    @click="() => $emit('click')"
  >
    <div :class="`btn-container ${spaced ? 'spaced' : ''}`">
      <v-icon left v-if="iconLeft"> {{ iconLeft }} </v-icon>
      <v-icon v-if="iconCenter"> {{ iconCenter }} </v-icon>
      <slot v-if="hasDefaultSlot" />
      <p v-else class="label">{{ label }}</p>
      <v-icon right v-if="iconRight"> {{ iconRight }} </v-icon>
    </div>
  </v-btn>
</template>

<script>
export default {
  computed: {
    classes() {
      const classes = ["Button"];
      if (this.color === "default") classes.push("btn-default");
      if (this.rounded) classes.push("rounded");
      return classes.join(" ");
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
  props: {
    label: { type: String, default: "" },
    to: { type: String, default: "" },
    color: { type: String, default: "primary" },
    disabled: { type: Boolean, default: false },
    iconRight: { type: String, default: "" },
    iconCenter: { type: String, default: "" },
    iconLeft: { type: String, default: "" },
    rounded: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    spaced: { type: Boolean, default: false },
  },
};
</script>

<style lang="sass" scoped>
.Button
  margin-bottom: .5rem
  border-radius: 4px
  box-shadow: none
.btn-container
  width: 100%
  .label
    margin-bottom: 0
.btn-default
  background-color: white !important
  color: $gray-600
  border: 2px solid $gray-400
.spaced
  display: flex
  justify-content: space-between
  align-items: center
.rounded
  border-radius: 30px!important
button
  text-align: center
  border-radius: 5px
</style>
