<template>
  <FormItemContainer :title="title">
    <v-text-field
      v-model="text"
      :class="classes"
      :type="passwordType || type"
      :append-icon="passwordIcon"
      @click:append="onAppendClick"
      @input="onInput"
      v-bind="props"
      :rules="myRules"
    />
  </FormItemContainer>
</template>

<script>
import FormItemContainer from "./FormItemContainer";
import { UpperCase } from "@/utils/Text";
import {
  validateEmail,
  validateLength,
  validateMaxLength,
  validateMinLength,
  validateMinNumber,
  validatePassword,
  validateRequired,
} from "@/utils/Validations";
export default {
  components: {
    FormItemContainer,
  },
  data() {
    return {
      text: this.value,
      showPassword: false,
    };
  },
  computed: {
    passwordIcon() {
      return (
        (this.isPassword && (this.showPassword ? "mdi-eye" : "mdi-eye-off")) ||
        null
      );
    },
    passwordType() {
      return this.isPassword && (this.showPassword ? "text" : "password");
    },
    props() {
      const props = { ...this.$attrs, ...this.$props };
      if (this.length || this.maxLength)
        props.counter = this.length || this.maxLength;
      return props;
    },
    myLengthText() {
      return this.lengthText || this.type === "number" ? "números" : null;
    },
    myRules() {
      const rules = [];
      if (this.isPassword) rules.push(validatePassword);
      if (this.length)
        rules.push((v) => validateLength(v, this.length, this.myLengthText));
      if (this.maxLength)
        rules.push((v) =>
          validateMaxLength(v, this.maxLength, this.myLengthText)
        );
      if (this.minLength)
        rules.push((v) =>
          validateMinLength(v, this.minLength, this.myLengthText)
        );
      if (this.required) rules.push(validateRequired);
      if (this.type === "email") rules.push(validateEmail);
      if (this.min !== null)
        rules.push((v) => validateMinNumber(v, this.min, this.minText));

      return [...rules, ...this.rules];
    },
    classes() {
      let classes = [];
      if (this.upperCase) classes.push("to-uppercase");
      return classes.join(" ");
    },
  },
  methods: {
    onAppendClick() {
      if (this.isPassword) {
        this.showPassword = !this.showPassword;
      }
    },
    onInput(e) {
      this.$emit("input", this.upperCase ? UpperCase(e) : e);
    },
  },
  watch: {
    value(value) {
      this.text = value;
    },
    text(value, oldValue) {
      if (this.maxLength && value?.length > this.maxLength) {
        this.$nextTick(() => {
          this.text = oldValue;
          this.onInput(oldValue);
        });
      }
    },
  },
  props: {
    isPassword: { type: Boolean, default: false },
    label: { type: String, default: null },
    length: { type: Number, default: null },
    lengthText: { type: String, default: null },
    maxLength: { type: Number, default: null },
    min: { type: Number, default: null },
    minLength: { type: Number, default: null },
    minText: { type: String, default: "monto" },
    outlined: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    title: { type: String, default: null },
    type: { type: String, default: null },
    upperCase: { type: Boolean, default: false },
    value: { type: String, default: null },
  },
};
</script>

<style lang="sass" scoped>
.field
  ::v-deep
    .v-text-field--outlined .v-label--active
      display: none
    legend
      width: 0 !important
    .v-text-field.v-text-field--enclosed .v-text-field__details
      margin-bottom: 4px !important
    .to-uppercase
      input
        text-transform: uppercase
</style>
