import axios from "axios";
import { apiEndPoint } from "@/config/constants.js";

const toast = (type, text) => {
  alert(text);
};
class Api {
  constructor() {
    this.requests = [];
    this._axios = axios.create({
      baseURL: apiEndPoint || "",
      // timeout: 1000,
      headers: {},
    });
    this._interceptors();
  }
  set Authorization(AUTH) {
    console.log("::AUTH", AUTH);
    this._axios.defaults.headers.Authorization = AUTH;
  }
  set baseUrl(url) {
    this._axios.defaults.baseURL = url;
  }
  get baseURL() {
    return this._axios.defaults.baseURL;
  }
  get cancelToken() {
    return axios.CancelToken.source();
  }
  _interceptors() {
    this._axios.interceptors.request.use((request) => {
      if (request.data?.cancelToken) delete request.data.cancelToken;
      // console.warn('::::::', request.url, request?.data);⛔️❗️
      return request;
    });
    this._interceptorsResponse();
  }
  _validateStringDataError(data) {
    if (!data) data = [];
    if (typeof data == "string") return data;
    if (data.length == undefined) data = [data];
    const aTexts = data.filter((message) => typeof message == "string");
    if (aTexts.length) return aTexts.join("<br />");
    else return null;
  }
  _interceptorsResponse() {
    this._axios.interceptors.response.use(
      (response) => {
        if (String(response.status).match(/20[0-9]/g)) {
          return response?.data;
        } else {
          console.warn("❗️ Request", { response });
        }
        return response;
      },
      (err) => {
        const { response, message } = err || {};
        const aborted = axios.isCancel(err);
        if (aborted) return Promise.reject({ data: [], error: err, aborted });
        const { status, data } = response || {};
        const aDigitStatus = status && Math.trunc(status / 100);
        const dataText = this._validateStringDataError(data?.data);
        const text = this._validateStringDataError(data?.text);
        const dataSourceError = data?.source_error || data?.status_code;
        const error =
          dataText || text || `${dataSourceError || ""} - ${message}`;
        if (aDigitStatus === 4) toast("alert", error, 5000);
        if (!aDigitStatus || aDigitStatus === 5) toast("error", error, 5000);
        let core_response = {
          data,
          error,
          aborted,
        };
        if (error === "Network Error") {
          toast("error", error, 5000);
          core_response.error = "";
        }
        // return core_response;
        return Promise.reject(core_response);
      }
    );
  }
  async _pendingRequests() {
    const statusPromises = [];
    this.requests.map((request) => {
      statusPromises.push(this._requestState(request));
    });
    const statutes = await Promise.all(statusPromises);
    return statutes.filter((r) => r.status !== "fulfilled");
  }
  _requestState(request) {
    const t = {};
    return Promise.race([request.axios, t]).then(
      (v) =>
        v === t
          ? { request, status: "pending" }
          : { request, status: "fulfilled" },
      () => ({ request, status: "rejected" })
    );
  }
  async cancelRequest({ regexRoute }) {
    const pending = await this._pendingRequests();
    pending.map((p) => {
      if (regexRoute && !!p.request.url.match(regexRoute))
        p.request.cancelSource.cancel(`Avoid multiple by ${regexRoute}`);
    });
  }

  makeRequest(request) {
    // this.cancelRequest(request)
    console.log("::", { request });
    const { method, data, url, headers = {} } = request;
    const cancelSource = this.cancelToken;
    const axiosRequest = {
      cancelToken: cancelSource.token,
      ...(data || {}),
    };
    const R = { url: `${method}:${url}`, cancelSource };
    R.axios = this._axios[method](`${url}`, axiosRequest, { headers });
    this.requests.push(R);
    return R.axios;
  }
  delete(url, data = {}) {
    return this.makeRequest({ method: "delete", url, data: { data } });
  }
  get(url, data = {}) {
    return this.makeRequest({ method: "get", url, data });
  }
  post(url, data = {}, headers) {
    return this.makeRequest({ method: "post", url, data, headers });
  }
  put(url, data = {}, headers) {
    return this.makeRequest({ method: "put", url, data, headers });
  }
  patch(url, data = {}) {
    return this.makeRequest({ method: "patch", url, data });
  }
}
export default new Api();
