<template>
  <section>
    <v-app-bar app color="primary" dark>
      <v-btn icon v-if="goBack" @click="handleGoBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <Container class="pa-4">
        <div v-if="iLoading" class="loading">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          />
        </div>
        <slot />
      </Container>
    </v-main>
    <v-btn
      v-if="$listeners.addClick"
      class="addButton"
      color="accent"
      dark
      absolute
      right
      fab
      @click="$emit('addClick')"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { Container } from "@/components";

export default {
  components: {
    Container,
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { layoutLoading }) => {
      if (type == "layoutLoading") {
        this.iLoading = layoutLoading;
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      iLoading: this.loading,
    };
  },
  methods: {
    handleGoBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    loading(value) {
      this.iLoading = value;
    },
    iLoading(value) {
      this.$emit("update:loading", value);
    },
  },
  props: {
    goBack: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    title: { type: String, default: "" },
  },
};
</script>

<style lang="sass" scoped>
#header
  background: red
.addButton
  bottom: 90px
.loading
  position: fixed
  display: flex
  justify-content: center
  align-items: center
  background: rgba(255,255,255,.8)
  left: 0
  right: 0
  bottom: 0
  top: 0
  z-index: 4
</style>
