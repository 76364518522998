export default [
  {
    path: "/clients",
    name: "Clients",
    component: () =>
      import(
        /* webpackChunkName: "clients" */ "../views/Clients/ClientsList.vue"
      ),
  },
  {
    path: "/clients/add",
    name: "Add clients",
    component: () =>
      import(
        /* webpackChunkName: "Add clients" */ "../views/Clients/ClientsForm.vue"
      ),
  },
  {
    path: "/clients/:clientId(\\d+)",
    name: "Edit clients",
    component: () =>
      import(
        /* webpackChunkName: "Edit clients" */ "../views/Clients/ClientsForm.vue"
      ),
  },
  {
    path: "/clients/:clientId(\\d+)/services",
    name: "Client services",
    component: () =>
      import(
        /* webpackChunkName: "Client services" */ "../views/Clients/ClientsServices.vue"
      ),
  },
  {
    path: "/clients/:clientId(\\d+)/services/:serviceId(\\d+)",
    name: "Client services details",
    component: () =>
      import(
        /* webpackChunkName: "Client services details" */ "../views/Clients/ClientsServiceDetails.vue"
      ),
  },
];
