<template>
  <v-list>
    <v-list-item-group :color="color" :mandatory="mandatory">
      <slot />
      <ListItem
        v-for="(item, index) in items"
        :key="`item-list-${index}`"
        :icon="itemIcon"
        :actionIcon="actionIcon"
        :title="item.title"
        :subTitle="item.subTitle"
        :active="item.active"
        :toParamKey="toParamKey"
        :to="to"
      />
    </v-list-item-group>
  </v-list>
</template>

<script>
import { ListItem } from "@/components";

export default {
  components: {
    ListItem,
  },
  props: {
    color: { type: String, default: "primary" },
    actionIcon: { type: String, default: "mdi-chevron-right" },
    itemIcon: { type: String, default: null },
    items: { type: Array, default: () => [] },
    mandatory: { type: Boolean, default: true },
    to: { type: String, default: null },
    toParamKey: { type: String, default: null },
  },
};
</script>

<style lang="sass" scoped></style>
