<template>
  <div class="search-history">
    <div class="search">
      <Field
        v-model="searchService"
        placeholder="Buscar"
        class="search-field"
      />
      <div class="container-filters">
        <h4>Filtrar por:</h4>
        <div>
          <Button
            rounded
            label="Servicios de Hoy"
            :class="`button mr-2 ${!serviceDate ? null : 'button-active'}`"
            @click="switchButtons"
          />
          <Button
            rounded
            label="Fecha"
            :class="`button ${serviceDate ? null : 'button-active'}`"
            @click="switchButtons"
          />
        </div>
      </div>
    </div>
    <DatePicker v-if="serviceDate" v-model="dates" isRange />
  </div>
</template>

<script>
import { DatePicker, Field, Button } from "@/components";

export default {
  components: {
    DatePicker,
    Field,
    Button,
  },
  mounted() {
    if (this.$route.query.enddate) this.getDataQuerys();
    else {
      this.dates = this.resetDates();

      if (this.$route.query.nameservice)
        this.searchService = this.$route.query.nameservice;
      else this.initialQuery = true;
    }
  },
  data() {
    return {
      initialQuery: false,
      searchService: null,
      dates: {
        startDate: null,
        endDate: null,
      },
      serviceDate: !!this.$route.query.enddate,
    };
  },
  methods: {
    switchButtons() {
      this.serviceDate = !this.serviceDate;
    },
    formateQuerys() {
      if (this.serviceDate) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            enddate: this.$moment().add(1, "day").format("YYYY-MM-DD"),
            startdate: this.$moment().format("YYYY-MM-DD"),
          },
        });
      } else {
        let query = { ...this.$route.query };
        delete query.enddate;
        delete query.startdate;
        this.$router.replace({ query });
      }
    },
    resetDates() {
      return {
        startDate: this.$moment().format("YYYY-MM-DD"),
        endDate: this.$moment().add(1, "day").format("YYYY-MM-DD"),
      };
    },
    changesDates() {
      if (this.serviceDate) this.$emit("sendDates", this.dates);
      else this.$emit("sendDates", this.resetDates());
    },
    getDataQuerys() {
      this.$emit("sendDates", {
        startDate: this.$route.query.startdate,
        endDate: this.$route.query.enddate,
      });

      if (this.$route.query.nameservice) {
        this.$emit("sendSearch", this.$route.query.nameservice);
        this.searchService = this.$route.query.nameservice;
      }
    },
    setSearch(value, timeout = 500) {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        let query = { ...this.$route.query };

        if (value) {
          query = {
            ...query,
            nameservice: value,
          };
        } else delete query.nameservice;

        if (this.initialQuery) this.$router.replace({ query });
        this.initialQuery = true;
        this.$emit("sendSearch", value);
      }, timeout);
    },
  },
  watch: {
    dates() {
      this.changesDates();
    },
    searchService(nameservice) {
      this.setSearch(nameservice);
    },
    serviceDate(value) {
      if (value) this.dates = this.resetDates();
      this.formateQuerys();
      this.changesDates();
    },
  },
};
</script>

<style lang="sass" scoped>
.search-history
  .search
    margin: -1rem
    background-color: $blue-400
    padding: 1rem
    margin-bottom: 1rem
    .container-filters
      display: flex
      align-items: center
      justify-content: flex-start
      & > div
        flex-grow: 1
      h4
        width: 75px
        color: white
        font-size: 14px
        margin-right: 10px
        font-weight: 400
      .button
        ::v-deep
          margin-bottom: 0
          padding: 0 10px
          font-size: 12px
          background: $blue-600 !important
          .v-btn--is-elevated
            box-shadow: none !important
          .btn-container
            font-weight: 400
      .button-active
        ::v-deep
          background-color: $gray-300 !important
          color: $gray-900 !important
    &-field
      margin-bottom: 0.5rem
      ::v-deep
        .v-input__slot
          border-radius: 100px
          min-height: 39px
          background-color: white !important
          border: none
          .fieldset
            border: none
        .v-text-field__details
          display: none
    @media only screen and (max-width: 361px)
      .container-filters
        flex-direction: column
        align-items: flex-start
      h4
        margin-bottom: 0.5rem
</style>
