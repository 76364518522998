<template>
  <v-list-item
    class="list-item"
    :class="active ? 'active' : 'inactive'"
    :to="navigateTo"
  >
    <v-list-item-icon v-if="icon">
      <v-icon v-text="icon" :size="sizeIcon" class="l-icon" />
    </v-list-item-icon>
    <v-list-item-content>
      <slot />
      <v-list-item-title v-if="title" v-text="title" class="l-title" />
      <v-list-item-subtitle
        v-if="subTitle"
        v-text="subTitle"
        class="l-subTitle"
      />
      <slot name="content" />
    </v-list-item-content>
    <v-list-item-action v-if="actionIcon || myActionText" class="l-action">
      <v-list-item-action-text
        v-if="myActionText"
        v-text="myActionText"
        class="l-aText"
      />
      <v-icon v-text="actionIcon" class="l-aIcon" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { toMoney } from "@/utils/Numeric";

export default {
  computed: {
    navigateTo() {
      const { toParamKey, to } = this;
      return `${to}${toParamKey ? `/${toParamKey}` : ""}`;
    },
    formatedAmount() {
      const { amount } = this;
      if (amount === 0) return toMoney(amount);
      return amount ? toMoney(amount) : "";
    },
    myActionText() {
      const { actionText, formatedAmount } = this;
      return actionText || formatedAmount;
    },
  },
  props: {
    actionIcon: { type: String, default: null },
    actionText: { type: String, default: null },
    active: { type: [Boolean, Number], default: true },
    amount: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: null },
    sizeIcon: { type: Number, default: 40 },
    subTitle: { type: String, default: null },
    title: { type: String, default: null },
    to: { type: String, default: null },
    toParamKey: { type: [String, Number], default: null },
  },
};
</script>

<style lang="sass" scoped>
.v-list-item__icon
  margin-right: 15px!important
.l-action
  display: flex
  align-items: center
  flex-direction: row
.l-aText
  font-weight: bold
  font-size: 14px
.active
  .v-icon,
  .l-aIcon,
  .l-subTitle,
  .l-icon
    color: var(--v-primary-base)
.inactive
   .v-icon,
  .l-aIcon,
  .l-title,
  .l-subTitle
    color: var(--v-secondary-lighten3)
</style>
