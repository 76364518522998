const isEmpty = (v) => v === undefined || v === null || v === "";

const rules = {
  email: {
    regex:
      /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    error: "Ingresa un correo valido",
  },
  length: (v, length, txt) => {
    console.log(v, length, txt);
    return v.length === length || `Ingresa ${length} ${txt || "caracteres"}`;
  },
  minLength: (v, length, txt) => {
    return (
      v.length >= length || `Ingresa al menos ${length} ${txt || "caracteres"}`
    );
  },
  maxLength: (v, length, txt) => {
    return (
      v.length <= length || `Ingresa menos de ${length} ${txt || "caracteres"}`
    );
  },
  password: {
    regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
    error: "Mínimo 6 carácteres. Debe contener número, minúscula y mayúscula.",
  },
  postalCode: {
    regex: /^[0-9]{5}$/,
    error: "Ingresa un código postal valido",
  },
  RFC: {
    regex:
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
    error: "Ingresa un RFC valido",
  },
  minNumber: (v, min, txt) => {
    return v >= min || `El ${txt || "valor"} mínimo debe ser ${min}`;
  },
  required: (v) => !isEmpty(v) || "El campo es requerido",
};

export const validateEmail = (v) =>
  v ? rules.email.regex.test(v) || rules.email.error : true;
export const validateLength = (v, length, txt) =>
  v ? rules.length(v, length, txt) : true;
export const validateMaxLength = (v, length, txt) =>
  v ? rules.maxLength(v, length, txt) : true;
export const validateMinLength = (v, length, txt) =>
  v ? rules.minLength(v, length, txt) : true;
export const validatePassword = (v) =>
  v ? rules.password.regex.test(v) || rules.password.error : true;
export const validatePostalCode = (v) =>
  v ? rules.postalCode.regex.test(v) || rules.postalCode.error : true;
export const validateRFC = (v) =>
  v ? rules.RFC.regex.test(v) || rules.RFC.error : true;
export const validateMinNumber = rules.minNumber;
export const validateRequired = rules.required;
