<template>
  <v-app>
    <router-view />
    <bottom-navigation />
  </v-app>
</template>

<script>
import { BottomNavigation } from "./components";

export default {
  components: { BottomNavigation },
  name: "App",
  data: () => ({
    //
  }),
};
</script>
