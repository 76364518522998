export const Strongify = (text = "", upper) =>
  `<strong class='${upper ? "upper" : "lower"}'>${text}</strong>`;

export const Capitalize = (str) => {
  if (!str) return;
  if (str.charAt(0) == " ") str = str.slice(1);
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const CamelCase = (str, separator = "") => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(separator);
};

const replaceCumulative = (str, find, replace) => {
  for (var i = 0; i < find.length; i++)
    str = str.replace(new RegExp(find[i], "g"), replace[i]);
  return str;
};

export const Normalize = (text, options = {}) => {
  const { encodeUri, lower, replace, upper } = options;
  if (Number(text)) return String(text);
  if (!text || typeof text !== "string") return text;

  text = text.trim(); // At begind
  if (lower) text = text.toLowerCase();
  if (upper) text = text.toUpperCase();
  if (replace) text = replaceCumulative(text, replace.find, replace.value);
  if (encodeUri) text = window.encodeURI(text); //At end
  return text;
};

export const UpperCase = (str) => {
  if (!str || typeof str !== "string") return "";
  return str.toUpperCase();
};
