<template>
  <div class="date-picker">
    <div @click="modal = true" class="time-picker">
      <v-icon>mdi-calendar</v-icon>
      <template v-if="isRange">
        <p>{{ startDate }} - {{ endDate }}</p>
      </template>
      <template v-else>
        <p>{{ startDate }}</p>
      </template>
      <v-icon>mdi-menu-down </v-icon>
    </div>
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent>
      <v-date-picker v-model="date" no-title scrollable :range="isRange">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="() => (modal = false)">
          Cancelar
        </v-btn>
        <v-btn text color="primary" @click="saveDates"> Guardar </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      date: this.currentDate(),
      dates: {
        startDate: this.$route.query.startdate || this.currentDate(),
        endDate: this.$route.query.enddate || this.currentDate(),
      },
    };
  },
  mounted() {
    if (this.$route.query.startdate && this.$route.query.enddate)
      this.date = [this.$route.query.startdate, this.$route.query.enddate];
  },
  computed: {
    startDate() {
      return this.$moment(this.dates.startDate).format("MMM D, YYYY");
    },
    endDate() {
      return this.$moment(this.dates.endDate).format("MMM D, YYYY");
    },
  },
  methods: {
    currentDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    saveDates() {
      this.$refs.dialog.save(this.date);
      this.$emit("input", this.dates);

      this.$router.replace({
        query: {
          ...this.$route.query,
          enddate: this.dates.endDate,
          startdate: this.dates.startDate,
        },
      });
    },
    createObject(firstDate, secondDate) {
      return {
        startDate: firstDate,
        endDate: secondDate || firstDate,
      };
    },
  },
  watch: {
    date(currentDates) {
      if (!this.isRange) {
        this.dates = {
          startDate: currentDates,
          endDate: null,
        };
        return;
      }

      let currentObjDates = {};
      const valueOfStart = this.$moment(currentDates[0]).valueOf();
      const valueOfEnd = this.$moment(currentDates[1]).valueOf();

      if (currentDates.length >= 2) {
        if (valueOfStart > valueOfEnd) {
          if (currentDates[1].length > 1) {
            currentObjDates = this.createObject(
              currentDates[1],
              currentDates[0]
            );
          } else currentObjDates = this.createObject(this.currentDate());
        } else {
          currentObjDates = this.createObject(currentDates[0], currentDates[1]);
        }
      } else {
        currentObjDates = this.createObject(currentDates[0]);
      }

      this.dates = currentObjDates;
    },
  },
  props: {
    isRange: { type: Boolean, default: false },
  },
};
</script>

<style lang="sass" scoped>
.date-picker
  .time-picker
    align-content: center
    align-items: center
    background-color: #007BFF
    border-radius: 4px
    color: white !important
    cursor: pointer
    display: flex
    font-size: 14px
    font-weight: 700
    height: 32px
    justify-content: center
    margin-bottom: 0.5rem
    padding: 0
    padding: 0.2rem
    p
      margin-bottom: 0
      margin: 0 0.4rem
    i
      color: white !important
</style>
