<template>
  <div v-if="simple">
    <p>-</p>
  </div>
  <div v-else class="empty">
    <v-icon class="e-icon" v-text="icon" size="70" />
    <div class="e-title">{{ title }}</div>
    <div class="e-text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  props: {
    icon: { type: String, default: "mdi-information-outline" },
    title: { type: String, default: "Sin resultados" },
    text: { type: String, default: "" },
    simple: { type: Boolean, default: false },
  },
};
</script>

<style lang="sass" scoped>
.empty
  height: 100%
  width: 100%
  padding: 20px
  margin: 0 auto
  background: white
  display: flex
  justify-content: center
  align-items: center
  flex-flow: column
  color: $gray-500
  .e-icon
    margin-bottom: 10px
    color: $gray-500
  .e-title
    font-weight: bold
    margin-bottom: 5px
  .e-text
    text-align: center
</style>
