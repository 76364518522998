export default [
  {
    path: "/schedule",
    name: "schedule",
    component: () =>
      import(
        /* webpackChunkName: "schedule" */ "../views/Schedule/ScheduleList.vue"
      ),
  },
  {
    path: "/schedule/:idService(\\d+)",
    name: "schedule details",
    component: () =>
      import(
        /* webpackChunkName: "schedule details" */ "../views/Schedule/ScheduleDetails.vue"
      ),
  },
  {
    path: "/schedule/add",
    name: "Add a schedule",
    component: () =>
      import(
        /* webpackChunkName: "Add schedule" */ "../views/Schedule/Schedule.vue"
      ),
  },
];
