<template>
  <v-bottom-navigation v-model="menuValue" color="primary" grow app mandatory>
    <v-btn value="Clientes" to="/clients">
      <span>Clientes</span>
      <v-icon>mdi-account-supervisor</v-icon>
    </v-btn>
    <v-btn value="Lavadores" to="/washers">
      <span>Lavadores</span>
      <v-icon>mdi-clipboard-account</v-icon>
    </v-btn>
    <v-btn value="Productos" to="/products">
      <span>Productos</span>
      <v-icon>mdi-package-variant</v-icon>
    </v-btn>
    <v-btn value="menu" @click="handleMenuClick">
      <span>Más</span>
      <v-icon>mdi-menu</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data() {
    return {
      menuValue: null,
    };
  },
  methods: {
    handleMenuClick() {
      // location.replace("menu");
      location.href = "menu";
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep
  .v-btn.v-size--default
    height: inherit
</style>
