export default [
  {
    path: "/products",
    name: "Products",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "../views/Products/ProductsList.vue"
      ),
  },
  {
    path: "/products/add",
    name: "Add products",
    component: () =>
      import(
        /* webpackChunkName: "Add products" */ "../views/Products/ProductsForm.vue"
      ),
  },
  {
    path: "/products/:productId(\\d+)",
    name: "Edit products",
    component: () =>
      import(
        /* webpackChunkName: "Edit products" */ "../views/Products/ProductsForm.vue"
      ),
  },
];
